import api from "./api";
import { TokenService } from "./token.service";

export const AuthService = {
  login,
  logout,
  isLoggedIn,
  getCurrentUser,
  getUsername,
  getUserId,
  updateUser,
  findRole,
  hasAccess,
  getAllAccessList,
};

function login(username, password) {
  return api
    .post("api/token/pair", {
      username,
      password,
    })
    .then((response) => {
      if (response?.data?.access) {
        response.data.username = username;
        TokenService.setUser(response?.data);
      }
      if (response.status == 408) {
        return response;
      } else if (response?.data) {
        response.data.status = response?.status;
      }
      return response?.data ? response?.data : response;
    });
}

function logout() {
  TokenService.removeUser();
  return true;
}

function isLoggedIn() {
  const user = TokenService.getUser();
  return !!user?.access;
}

function findRole() {
  const user = TokenService.getUser();
  return user?.kind;
}

function hasAccess(pagename, operation = "view") {
  const access = TokenService.getAccess();
  return access && access[pagename]?.operations[operation] ? true : false;
}

function getAllAccessList(pagename) {
  const access = TokenService.getAccess();
  return access && access[pagename]?.operations;
}

function getCurrentUser() {
  return TokenService.getUser();
}

function getUsername() {
  const user = TokenService.getUser();
  return user?.username;
}

function getUserId() {
  const userId = TokenService.getUserId();
  return userId;
}

function updateUser(data) {
  TokenService.updateLocalUser(data);
  return true;
}
