export const BaseRole = {
  Admin: "Admin",
  Product: "Product",
};

export const ADMIN_ROLE_ACCESS = {
  user: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
      update: true,
      delete: true,
      changePassword:true
    },
  },
  feature: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
      update: true,
      delete: true,
    },
  },
  coreengine: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
      update: true,
      delete: true,
    },
  },
  coreengine_feature: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
      update: true,
      delete: true,
    },
  },
  products: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
      update: true,
      delete: true,
      enabled: true,
    },
  },
  products_apps: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
      update: true,
      delete: true,
      enabled: true,
    },
  },
  products_user: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
      update: true,
      delete: true,
    },
  },
  products_apps_testengine: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
      update: true,
      delete: true,
    },
  },
  builds: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
    },
  },
  dashboard: {
    operations: {
      view: true,
      // viewdetails : true,
      // add : true,
      // update : true,
      // delete: true
    },
  },
  document: {
    operations: {
      view: true,
      // viewdetails : true,
      // add : true,
      // update : true,
      // delete: true
    },
  },
};

export const PRODUCT_ROLE_ACCESS = {
  user: {
    operations: {
      view: false,
      viewdetails: false,
      add: false,
      update: true,
      delete: false,
      changePassword:true
    },
  },
  feature: {
    operations: {
      view: false,
      viewdetails: false,
      add: false,
      update: false,
      delete: false,
    },
  },
  coreengine: {
    operations: {
      view: false,
      viewdetails: false,
      add: false,
      update: false,
      delete: false,
    },
  },
  coreengine_feature: {
    operations: {
      view: false,
      viewdetails: false,
      add: false,
      update: false,
      delete: false,
    },
  },
  products: {
    operations: {
      view: true,
      viewdetails: true,
      add: false,
      update: false,
      delete: false,
      enabled: false,
    },
  },
  products_apps: {
    operations: {
      view: true,
      viewdetails: true,
      add: false,
      update: false,
      delete: false,
      enabled: false,
    },
  },
  products_user: {
    operations: {
      view: true,
      viewdetails: true,
      add: false,
      update: false,
      delete: false,
    },
  },
  products_apps_testengine: {
    operations: {
      view: true,
      viewdetails: true,
      add: false,
      update: false,
      delete: false,
    },
  },
  builds: {
    operations: {
      view: true,
      viewdetails: true,
      add: true,
    },
  },
  dashboard: {
    operations: {
      view: true,
      // viewdetails : true,
      // add : false,
      // update : false,
      // delete: false
    },
  },
  document: {
    operations: {
      view: true,
      // viewdetails : true,
      // add : false,
      // update : false,
      // delete: false
    },
  },
};

// export const Role = {
//     user:{
//         operations : {
//             view : [BaseRole.Admin],
//             viewdetails : [BaseRole.Admin],
//             add : [BaseRole.Admin],
//             update : [BaseRole.Admin],
//             delete: [BaseRole.Admin]
//         }
//     },
//     feature:{
//         operations : {
//             view : [BaseRole.Admin],
//             viewdetails : [BaseRole.Admin],
//             add : [BaseRole.Admin],
//             update : [BaseRole.Admin],
//             delete: [BaseRole.Admin]
//         }
//     },
//     coreengine:{
//         operations : {
//             view : [BaseRole.Admin],
//             viewdetails : [BaseRole.Admin],
//             add : [BaseRole.Admin],
//             update : [BaseRole.Admin],
//             delete: [BaseRole.Admin]
//         }
//     },
//     products:{
//         operations : {
//             view : [BaseRole.Admin, BaseRole.Product],
//             viewdetails : [BaseRole.Admin, BaseRole.Product],
//             add : [BaseRole.Admin],
//             update : [BaseRole.Admin],
//             delete: [BaseRole.Admin]
//         }
//     },
//     products_apps:{
//         operations : {
//             view : [BaseRole.Admin, BaseRole.Product],
//             viewdetails : [BaseRole.Admin, BaseRole.Product],
//             add : [BaseRole.Admin],
//             update : [BaseRole.Admin],
//             delete: [BaseRole.Admin]
//         }
//     },
//     products_apps_testengine:{
//         operations : {
//             view : [BaseRole.Admin, BaseRole.Product],
//             viewdetails : [BaseRole.Admin, BaseRole.Product],
//             add : [BaseRole.Admin],
//             update : [BaseRole.Admin],
//             delete: [BaseRole.Admin]
//         }
//     },
//     builds:{
//         operations : {
//             view : [BaseRole.Admin, BaseRole.Product],
//             viewdetails : [BaseRole.Admin, BaseRole.Product],
//             add : [BaseRole.Admin, BaseRole.Product],
//         }
//     },
//     dashboard:{
//         operations : {
//             view : [BaseRole.Admin, BaseRole.Product],
//             // viewdetails : [BaseRole.Admin, BaseRole.Product],
//             // add : [BaseRole.Admin],
//             // update : [BaseRole.Admin],
//             // delete: [BaseRole.Admin]
//         }
//     },
//     document:{
//         operations : {
//             view : [BaseRole.Admin, BaseRole.Product],
//             // viewdetails : [BaseRole.Admin, BaseRole.Product],
//             // add : [BaseRole.Admin],
//             // update : [BaseRole.Admin],
//             // delete: [BaseRole.Admin]
//         }
//     },
// }
